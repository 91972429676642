@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://use.typekit.net/wik3ney.css");
@import "./typography.scss";

@layer base {
  :root {
    --color-white: #ffffff;

    --color-black: #000000;

    --color-nsc-yellow: #ffd000;
    --color-nsc-yellow-hover: #f8cb04;

    --color-nsc-dark-blue: #171c60;
    --color-nsc-dark-blue-hover: #0b0e4b;

    --color-nsc-europe-blue: #01339b;
    --color-nsc-europe-blue-hover: #01339b;

    --color-nsc-light-gray: #f2f2f2;
    --color-nsc-light-gray-hover: #f2f2f2;

    --color-nsc-light-blue: #79a7e9;
    --color-nsc-light-blue-hover: #79a7e9;

    --color-nsc-ice-blue: #d1e3f1;
    --color-nsc-ice-blue-hover: #d1e3f1;

    --color-nsc-mint: #79c6bd;
    --color-nsc-mint-hover: #79c6bd;

    --color-nsc-dark-brown: #583f31;
    --color-nsc-dark-brown-hover: #583f31;

    --color-nsc-orange: #f47512;
    --color-nsc-orange-hover: #f47512;

    --color-jsc-yellow: #fff200;
    --color-jsc-yellow-hover: #eee200;

    --color-jsc-sky-blue: #007bb8;
    --color-jsc-sky-blue-hover: #0073ad;

    --color-jsc-medium-gray: #d9d9d9;
    --color-jsc-medium-gray-hover: #d9d9d9;

    --color-jsc-dark-gray: #303338;
    --color-jsc-dark-gray-hover: #303338;

    --radius: 0.5rem;
  }
}

@layer utilities {
  .clip-custom-shape {
    clip-path: url(#customClipPath);
  }

  @media (max-width: 768px) {
    .clip-custom-shape {
      clip-path: url(#customClipPathMobile);
    }
  }
}

.grid-container {
  @apply grid grid-cols-4 gap-x-5 md:grid-cols-12;
}

.container-border {
  @apply border border-x border-solid border-nsc-dark-blue opacity-5;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

@layer base {
  * {
    scroll-padding-top: 80px;
  }

  body {
    @apply w-full overflow-x-hidden bg-white text-nsc-dark-blue;
  }

  .jsc {
    body {
      @apply text-jsc-dark-gray;
    }

    body {
      @apply font-lato;
    }
  }
}

html {
  overflow-x: hidden;
}

.rich-text {
  p {
    @apply relative mt-6 text-pretty text-lg font-light leading-[30px];
  }

  iframe {
    @apply w-[800px] min-h-[420px];
  }

  h2 {
    @apply text-balance font-oswald text-[32px] font-bold uppercase leading-[110%] lg:text-[48px];
  }

  h3 {
    @apply mt-6 text-balance font-oswald text-[28px] font-bold uppercase leading-[110%] lg:text-[32px];
  }

  strong {
    @apply font-bold;
  }

  a {
    @apply font-[inherit] text-lg font-light underline underline-offset-2;
    text-transform: none;
  }

  > * {
    @apply relative mt-6;
  }

  > :first-child {
    @apply mt-0;
  }

  &.no-balance {
    p {
      @apply text-wrap;
    }
  }

  blockquote {
    @apply text-balance pl-6 text-base font-light leading-[30px] md:pl-10 md:text-lg;

    p {
      @apply text-wrap;
    }

    &::before {
      content: "”";
      @apply absolute left-0 top-2 text-balance text-[52px] font-light leading-[30px] md:left-[0px] md:top-[0px] md:text-[52px];
    }
  }

  ul {
    @apply list-inside list-none pl-7 text-lg font-light;

    li {
      @apply relative mt-2;

      &:first-child {
        @apply mt-0;
      }
    }

    li::before {
      content: "";
      @apply absolute -left-7 top-[13px] h-[11px] w-[11px] -translate-y-1/2 rounded-full bg-nsc-yellow;
    }
  }

  ol {
    @apply list-inside list-none pl-7 text-lg font-light;

    li::before {
      content: counter(list-item);
      @apply absolute -left-7 top-[10px] h-[11px] w-[11px] -translate-y-1/2 font-roboto text-sm font-bold text-nsc-dark-blue;
    }

    li {
      @apply relative mt-2;

      &:first-child {
        @apply mt-0;
      }
    }
  }

  &.singleton {
    h2 {
      @apply mt-6 text-balance font-oswald text-[28px] font-bold uppercase leading-[110%] lg:text-[32px];
    }
  }
}

.rich-text-hero {
  p {
    @apply text-base;
  }

  &.text-white {
    p {
      @apply text-[#fffff];
    }

    a {
      @apply text-[#fffff];
    }

    .cta-link {
      @apply text-[#fffff];
    }
  }

  > * {
    @apply relative mt-6;
  }

  > :first-child {
    @apply mt-0;
  }

  strong {
    @apply font-bold;
  }

  a {
    @apply font-[inherit] text-lg font-light underline underline-offset-2;
    text-transform: none;
  }
}

.button {
  @apply inline-flex items-center justify-center whitespace-nowrap header-6 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50;
}

.nsc {
  .rich-text {
    p {
      @apply font-calluna;
    }

    ol, ul {
      @apply font-calluna;
    }

    blockquote {
      @apply font-calluna;

      &::before {
        @apply font-calluna
      }
    }

    .cta-link {
      @apply flex items-center gap-1 font-roboto text-lg font-light underline underline-offset-2;
      text-transform: none;
    }
  }

  .rich-text-hero {
    h2 {
      @apply header-2;
    }

    h3 {
      @apply mt-6 header-3;
    }

    p {
      @apply mt-6 text-pretty font-calluna text-lg font-light leading-[140%] md:text-[22px];
    }

    .cta-link {
      @apply font-roboto text-lg font-light underline underline-offset-2;
      text-transform: none;
    }
  }
}

.jsc {
  .button {
    @apply rounded-full;
  }

  .cta-link {
    @apply inline-flex items-center gap-4 font-lato text-lg normal-case font-light underline underline-offset-2;
  }

  .rich-text {
    p {
      @apply font-lato font-normal;
    }

    ol, ul, li {
      @apply font-lato font-normal;
    }

    ul {
      li::before {
        content: "";
        @apply bg-jsc-yellow;
      }
    }

    blockquote {
      @apply font-lato font-normal;
    }
  }

  .jsc-sky-blue-bg-section {
    .rich-text {
      .cta-link {
        @apply font-oswald justify-center no-underline uppercase bg-white text-jsc-sky-blue rounded-full py-2 px-6 md:min-w-[300px];

        svg path {
          fill: var(--color-jsc-sky-blue) !important;
        }
      }
    }
  }

  .rich-text-hero {
    h2 {
      @apply header-3;
    }

    h3 {
      @apply mt-6 header-4;
    }

    p {
      @apply mt-6 text-pretty font-lato text-lg font-normal leading-[140%] md:text-[20px];
    }

    .cta-link {
      @apply font-oswald text-black uppercase;
    }
  }
}

.small-ellipse-section {
  clip-path: ellipse(150% 50% at 50% 50%);
}

.medium-ellipse-section {
  clip-path: ellipse(250% 50% at 50% 50%);
}

.large-ellipse-section {
  clip-path: ellipse(350% 50% at 50% 50%);
}

@media (max-width: 768px) {
  .small-ellipse-section {
    clip-path: ellipse(250% 50% at 50% 50%);
  }

  .medium-ellipse-section {
    clip-path: ellipse(400% 50% at 50% 50%);
  }

  .large-ellipse-section {
    clip-path: ellipse(650% 50% at 50% 50%);
  }
}

// Customize Accordion
html.nsc [data-state=open] > svg > path:first-child {
  opacity: 0;
}

html.nsc [data-custom-state=open] > svg {
  --tw-translate-x: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

html.nsc [data-custom-state=open] > svg > path:last-child {
  opacity: 0;
}
